import * as React from 'react';
import { useSelector } from 'react-redux';

import { JsonQuery } from 'shared/common/packages/JsonQuery';
// eslint-disable-next-line import/no-restricted-paths
import { selectParentRegionId } from 'shared/map-search/selectors/parentRegionId';

import { SubsidisedMortgage, TOption } from '../../../components/advancedFilters/SubsidisedMortgage';
import {
  SUBSIDIZED_MORTGAGE_EXCLUDED_REGIONS_KEY,
  SUBSIDIZED_MORTGAGE_LABEL_TEXT_KEY,
} from '../../../constants/config';
import { useContext } from '../../../utils/useContext';

export const SubsidisedMortgageContainer: React.FC = () => {
  const {
    jsonQuery,
    onChange,
    appContext: { config },
  } = useContext();
  const isSubsidisedMortgage = new JsonQuery(jsonQuery).getIsSubsidisedMortgage();
  const parentRegionId = useSelector(selectParentRegionId);

  const label = config.get<string>(SUBSIDIZED_MORTGAGE_LABEL_TEXT_KEY) || '';
  const subsidizedMortgageExcludedRegions = config.get<number[]>(SUBSIDIZED_MORTGAGE_EXCLUDED_REGIONS_KEY) ?? [];

  const options: TOption[] = [
    { label: 'Неважно', value: null },
    { label, value: true },
  ];

  const handleChange = React.useCallback(
    (isSubsidisedMortgage: boolean | null) => {
      onChange({ action: 'setIsSubsidisedMortgage', arguments: [isSubsidisedMortgage] });
    },
    [onChange],
  );

  if (!label || (parentRegionId !== null && subsidizedMortgageExcludedRegions.includes(parentRegionId))) {
    return null;
  }

  return <SubsidisedMortgage onChange={handleChange} value={isSubsidisedMortgage} options={options} />;
};

import { EParentRegionActionType, TParentRegionActions } from 'shared/map-search/actions/parentRegionId';

const defaultState: number | null = null;

export function parentRegionIdReducer(state = defaultState, action: TParentRegionActions): number | null {
  switch (action.type) {
    case EParentRegionActionType.SetParentRegionId:
      return action.payload;
    default:
      return state;
  }
}

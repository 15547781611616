import { IConfig } from '@cian/config/shared';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  SUBSIDIZED_MORTGAGE_ENABLED_KEY,
  SUBSIDIZED_MORTGAGE_EXCLUDED_REGIONS_KEY,
  SUBSIDIZED_MORTGAGE_LABEL_TEXT_IN_MIXED_LISTING_KEY,
} from 'shared/common/packages/Filters/shared/constants/config';
import { FOfferType, offerTypeFromJsonQuery } from 'shared/common/packages/JsonQuery';
// eslint-disable-next-line import/no-restricted-paths
import { selectParentRegionId } from 'shared/map-search/selectors/parentRegionId';

import { SUBSIDIZED_MORTGAGE_OPTION_VALUE } from '../constants';

interface IParams {
  jsonQuery: TJsonQuery;
  config: IConfig;
}

export const useSubsidizedMortgageOption = ({ jsonQuery, config }: IParams) => {
  const offerType = offerTypeFromJsonQuery(jsonQuery);

  const parentRegionId = useSelector(selectParentRegionId);

  const subsidizedMortgageEnabled = config.get<boolean>(SUBSIDIZED_MORTGAGE_ENABLED_KEY);
  const subsidizedMortgageLabelText = config.get<string>(SUBSIDIZED_MORTGAGE_LABEL_TEXT_IN_MIXED_LISTING_KEY) ?? '';
  const subsidizedMortgageExcludedRegions = config.get<number[]>(SUBSIDIZED_MORTGAGE_EXCLUDED_REGIONS_KEY) ?? [];

  const isSubsidizedMortgageOptionAvailable =
    subsidizedMortgageEnabled &&
    offerType !== FOfferType.FlatNew &&
    subsidizedMortgageLabelText &&
    parentRegionId !== null &&
    !subsidizedMortgageExcludedRegions.includes(parentRegionId);

  return useMemo(() => {
    if (isSubsidizedMortgageOptionAvailable) {
      return {
        label: subsidizedMortgageLabelText,
        value: SUBSIDIZED_MORTGAGE_OPTION_VALUE,
      };
    }

    return undefined;
  }, [isSubsidizedMortgageOptionAvailable, subsidizedMortgageLabelText]);
};

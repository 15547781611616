import { Desktop } from '../../../common/packages/Responsive';
import { PageLayout } from '../../components/PageLayout';
import { BannersContainer } from '../BannersContainer';
import { FiltersContainer } from '../FiltersContainer';
import { Map } from '../Map';
import { NavigationContainer } from '../Navigation';
import { Notifications } from '../Notifications';
import { SaveSearchModalContainer } from '../SaveSearchModalContainer';
import { SidebarContainer } from '../SidebarContainer';

export function PageContainer() {
  return (
    <>
      <PageLayout
        navigation={
          <>
            <Desktop>
              <FiltersContainer />
            </Desktop>
            <NavigationContainer />
          </>
        }
        sidebar={
          <Desktop>
            <SidebarContainer />
          </Desktop>
        }
        map={<Map />}
      />
      <SaveSearchModalContainer />
      <Notifications />
      <BannersContainer />
    </>
  );
}

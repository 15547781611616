import { IMultiSuggestResult, IItemClickParams, ESuggestType } from '@cian/geosuggest-widget';
import { useEventCallback } from '@cian/react-utils';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import {
  dealTypeFromJsonQuery,
  offerTypeFromJsonQuery,
  FDealType,
  FOfferType,
  isCommercial,
  getTermsValue,
} from 'shared/common/packages/JsonQuery';
// eslint-disable-next-line import/no-restricted-paths
import { setParentRegion } from 'shared/map-search/actions/parentRegionId';
/**
 * FIXME убрать зависимость фильтров от карты
 */
// eslint-disable-next-line import/no-restricted-paths
import { TThunkDispatch } from 'shared/map-search/types/redux';

import { normalizeGeoObject, getExtendedRegion } from './helpers';
import { trackFocus, trackEmptyResults, trackChange } from './tracking';
import { ISuggestGeoItem } from './types';
import { geocode } from '../../../actions/geocode';
import { suggest } from '../../../actions/suggestions';
import { Geo } from '../../../components/filters/Geo';
import { TModifier } from '../../../types/modifier';
import { useContext } from '../../../utils/useContext';
import { useDebouncedCallback } from '../../../utils/useDebouncedCallback';

export const GeoContainer: React.FC = () => {
  const dispatch = useDispatch<TThunkDispatch>();
  const { jsonQuery, onChange, onApply } = useContext();
  const regionIds: number[] | null = getTermsValue('region')(jsonQuery);
  const [value, setValue] = React.useState<string | null>(null);
  const [suggestions, setSuggestions] = React.useState<IMultiSuggestResult | null>(null);

  const getSuggestions = useDebouncedCallback(async () => {
    if (!value) {
      return;
    }

    setSuggestions(await dispatch(suggest(value)));
  }, 300);

  const handleValueChange = useEventCallback((value: string | null) => {
    setValue(value);
    getSuggestions();
  });

  React.useEffect(() => {
    if (jsonQuery.region?.value[0] !== undefined) {
      dispatch(setParentRegion(jsonQuery.region?.value[0]));
    }
  }, [jsonQuery]);

  const handleItemSelect = React.useCallback(
    async (params: IItemClickParams) => {
      const modifiers: TModifier[] = [];

      switch (params.group) {
        case ESuggestType.geo:
          try {
            const geocodedItem = await dispatch(geocode(params.title));

            const isRegion =
              geocodedItem.geoValue.type === 'location' && geocodedItem.geoValue.id === geocodedItem.regionId;

            if (geocodedItem.regionId && (!regionIds?.includes(geocodedItem.regionId) || isRegion)) {
              modifiers.push({ action: 'setRegion', arguments: [getExtendedRegion(geocodedItem.regionId)] });
            }

            if (!isRegion) {
              modifiers.push({ action: 'selectGeo', arguments: [geocodedItem.geoValue] });
            }

            trackChange('yandex', geocodedItem.geoValue.type);
            // eslint-disable-next-line no-empty
          } catch {}

          break;
        case ESuggestType.city:
          if (params.regionId) {
            if (!regionIds?.includes(params.regionId)) {
              modifiers.push({ action: 'setRegion', arguments: [getExtendedRegion(params.regionId)] });
            }
            modifiers.push({ action: 'selectGeo', arguments: [normalizeGeoObject(params as ISuggestGeoItem)] });
          } else {
            if (params.id) {
              if (!regionIds?.includes(params.id)) {
                modifiers.push({ action: 'setRegion', arguments: [getExtendedRegion(params.id)] });
              }
            } else {
              modifiers.push({ action: 'selectGeo', arguments: [normalizeGeoObject(params as ISuggestGeoItem)] });
            }
          }
          break;
        case ESuggestType.district:
        case ESuggestType.road:
        case ESuggestType.underground:
        case ESuggestType.railway:
        case ESuggestType.regionalDistrict:
          if (params.regionId && !regionIds?.includes(params.regionId)) {
            modifiers.push({ action: 'setRegion', arguments: [getExtendedRegion(params.regionId)] });
          }
          modifiers.push({ action: 'selectGeo', arguments: [normalizeGeoObject(params as ISuggestGeoItem)] });
          break;
        case ESuggestType.newbuilding: {
          const dealType = dealTypeFromJsonQuery(jsonQuery);
          if (dealType !== FDealType.Sale) {
            modifiers.push({ action: 'setDealType', arguments: [FDealType.Sale] });
          }

          const offerType = offerTypeFromJsonQuery(jsonQuery);
          if (offerType !== FOfferType.Flat) {
            modifiers.push({ action: 'setOfferType', arguments: [FOfferType.Flat] });
          }

          if (params.regionId && !regionIds?.includes(params.regionId)) {
            modifiers.push({ action: 'setRegion', arguments: [getExtendedRegion(params.regionId)] });
          }
          modifiers.push({ action: 'selectGeo', arguments: [normalizeGeoObject(params as ISuggestGeoItem)] });
          break;
        }

        case ESuggestType.village: {
          const dealType = dealTypeFromJsonQuery(jsonQuery);
          if (dealType !== FDealType.Sale) {
            modifiers.push({ action: 'setDealType', arguments: [FDealType.Sale] });
          }

          const offerType = offerTypeFromJsonQuery(jsonQuery);
          if (((FOfferType.House | FOfferType.Townhouse | FOfferType.Land) & offerType) === 0) {
            modifiers.push({ action: 'setOfferType', arguments: [FOfferType.House] });
          }

          if (params.regionId && !regionIds?.includes(params.regionId)) {
            modifiers.push({ action: 'setRegion', arguments: [getExtendedRegion(params.regionId)] });
          }
          if (params.id) {
            modifiers.push({ action: 'setVillage', arguments: [params.id] });
          }
          break;
        }

        case ESuggestType.businessCenter:
        case ESuggestType.shoppingCenter: {
          const dealType = dealTypeFromJsonQuery(jsonQuery);
          if (![FDealType.RentLongterm, FDealType.Sale].includes(dealType)) {
            modifiers.push({ action: 'setDealType', arguments: [FDealType.Rent] });
          }

          const offerType = offerTypeFromJsonQuery(jsonQuery);
          if (isCommercial(offerType)) {
            modifiers.push({ action: 'setOfferType', arguments: [FOfferType.Office] });
          }

          if (params.regionId && !regionIds?.includes(params.regionId)) {
            modifiers.push({ action: 'setRegion', arguments: [getExtendedRegion(params.regionId)] });
          }

          if (params.id) {
            modifiers.push({ action: 'setBSCenter', arguments: [params.id] });
          }
          break;
        }
      }

      if (modifiers.length > 0) {
        if (modifiers.some(m => m.action === 'setRegion')) {
          modifiers.unshift({ action: 'clearGeo', arguments: [] });
        }

        onChange(...(modifiers as [TModifier, ...TModifier[]]));
        onApply();

        setValue(null);
      }

      if (params.group !== ESuggestType.geo) {
        trackChange('cian', params.group);
      }
    },
    [dispatch, jsonQuery, onApply, onChange, regionIds],
  );

  const handleFocus = React.useCallback(() => {
    trackFocus();
  }, []);

  const handleEmptyResults = React.useCallback((value: string) => {
    trackEmptyResults(value);
  }, []);

  return (
    <Geo
      value={value}
      suggestions={suggestions}
      onValueChange={handleValueChange}
      onItemSelect={handleItemSelect}
      onFocus={handleFocus}
      onEmptyResults={handleEmptyResults}
    />
  );
};
